import { useState, useEffect, useRef, useContext } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { useParams } from 'react-router-dom';
import parser from 'bbcode-to-react';
import { useTranslation } from 'react-i18next';

// import { GlobalDispatchContext } from '../../components/context/global';
import { GlobalStateContext } from '../../components/context/global';

// import ExitIcon from '../../components/modal/exiticon';
import GalleryDialog from '../../components/modal/gallery';
import api from '../../components/service/api.ts';
import { parseJSON } from '../../components/util/index';
import Spinner, { SpinnerFixed } from '../../components/spinner';

import ReactHtmlParser from 'react-html-parser';
import LazyImage from '../../components/hook/lazyimage';

import InputCounter from '../../components/hook/inputcounter';
import CartButton from '../../components/hook/cartbutton';
import FaviButton from '../../components/hook/favibutton';
import useCartAction from '../../components/hook/cartaction';
import SSelect from '../../components/grid/sselect';

import Battery from '../../components/battery';
// import LabelTag from '../../components/labeltag';

import { numberFormatCurrency, numberFormatQuantity, roundTo } from '../../components/util/index';

import CartContext from '../../components/context/cart.ts';
import CartSelect from '../../components/cart/cartselect';
import BreadCrumbs from './breadcrumbs';

import useMenuTree from '../../components/hook/menu/menutree';

import DocumentList from '../../components/list/DocumentList';
import FeatureList from '../../components/list/FeatureList';
import useFileCache from '../../components/hook/useFileCache';
import Alert from '../../components/alert';

import MenuSide from '../../components/side/menuside';
import PropSelect from '../../components/propselect';
import PropDisplay from '../../components/propselect/display';

import ArticleGrid from './grid';
import CalcProduct from '../cart/CalcProduct';
import BranchCalcProduct from '../cart/BranchCalcProduct';
import IconDiscount from '../../components/icon/IconDiscount';

import AlertDialog from '../../components/modal/AlertDialog';

import { IconSpinner } from '../../components/icon';


const getArticle = async (symbol) => {
  const resp = await api.getArticleBySymbol(symbol);
  return resp?.data;
};

const getArticleCrossSell = async (symbol) => {
  const resp = await api.getArticleCrossSelling(symbol);
  return resp?.data;
};

const getArticleVariant = async (symbol) => {
  const resp = await api.getArticleVariants(symbol);
  return resp?.data;
};

const getArticleProperty = async (symbolArr) => {
  const resp = await api.getArticleProperties(symbolArr);
  return resp?.data;
};

const getArticleQuantity = async (artList) => {
  const response = await api.getArticleQuantity(artList);
  return response?.data;
};

const getArticlePrice = async (artList) => {
  const response = await api.getArticlePrice(artList);
  return response?.data;
};

const getArticleStickerCount = async (symbol) => {
  const response = await api.getArticleFilesCount(symbol, 'E');
  return response?.data;
};

const getArticleImagesPack = async (symbol) => {
  const resp = await api.getArticleFilesPack(symbol);

  if (!resp) {
    AlertDialog({
      title: 'Informacja',
      message: 'Nie udało się pobrać pliku',
      color: 'yellow',
    });

    return;
  }

  const url = window.URL.createObjectURL(new Blob([resp.data]));
  const link = document.createElement('A');
  link.style = 'display: none';
  link.href = url;
  // link.setAttribute("href", url);
  link.setAttribute('download', `b2b_photos_${symbol}.zip`);
  document.body.appendChild(link);
  // document.getElementsByTagName("body")[0].append(link);
  link.click();
  window.URL.revokeObjectURL(url);
  link.remove();
};

const getCartElementList = async (nagId) => {
  const resp = await api.getCartElement(nagId);
  return resp?.data;
};

const ArticleProperties = ({ item }) => {
  const [data, setData] = useState({definition: [], selection: []});
  const [cartState, cartAction] = useCartAction();

  useEffect(() => {
    let isMounted = true;

    Promise.all([getArticleProperty([item.symbol_art]), getCartElementList(+cartState.cart.selectNormal)])
      .then((values) => {
        if (values[0]?.status != 0 || values[1]?.status != 0) return;
        const dataPropertyDef = values[0].dane;
        const dataPropertySel = values[1].dane;

        const jsonPropertyDef = parseJSON(dataPropertyDef);
        if (jsonPropertyDef === undefined) return;

        const jsonPropertySel = parseJSON(dataPropertySel);
        if (jsonPropertySel === undefined) return;

        const uniqueProperties = new Set();
        const propertyDefinition = jsonPropertyDef[0].cechy.filter((elem) => {
          const isDuplicate = uniqueProperties.has(elem.id);
          uniqueProperties.add(elem.id);
          return !isDuplicate;
        });

        const positionInCart = cartAction.getPosition(item);
        // const propertySelection = 
        //   Object.entries(cartAction.getProperty(item, positionInCart) || {})
        //     .map(([field, value]) => ({field, value}));

        const propertySelection = jsonPropertySel
          .filter(el => el.symbol_art == item.symbol_art && (positionInCart == el.id || positionInCart == 0) && el.cechy.length > 0)
          .map(el => parseJSON(el.cechy)).pop()

        // console.log("ARTICLE_PROPERTIES", positionInCart, jsonPropertySel, propertySelection, cartState.position);
        if (isMounted)
          setData({ definition: propertyDefinition, selection: propertySelection || [] });
    });

    return () => {
      isMounted = false;
    }
  }, []);

  if (data.definition.length === 0) return '';

  return (
    <>
      <PropDisplay item={item} property={data} />
    </>
  );
};

const ArticleTabs = ({ item, files = [], isPopup = false }) => {
  const { t } = useTranslation("shop");

  const [articleCrossList, setArticleCrossList] = useState([]);
  const [articleCrossMultiList, setArticleCrossMultiList] = useState([]);
  const [articleVariantList, setArticleVariantList] = useState([]);
  const [articleDocumentList, setArticleDocumentList] = useState([]);
  const [articleFeatureList, setArticleFeatureList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [tabNum, setTabNum] = useState(1);
  const [tabList, setTabList] = useState([
    { title: t('Opis') },
    { title: t('Cechy'), state: 0},
    { title: t('Do\u00A0pobrania'), state: 1 },
    { title: t('Warianty'), state: 2 },
    { title: t('Powiązane'), state: 3 },
    { title: t('Powiązane wielo'), state: 4 },
  ]);

  const stateBag = [
    articleFeatureList,
    articleDocumentList,
    articleVariantList,
    articleCrossList,
    articleCrossMultiList,
  ];

  const tabShow = (e, tab) => {
    e && e.preventDefault();
    setTabNum(tab);
  };

  useEffect(() => {
    if (isPopup == true) return;

    let isMounted = true;
    setLoading(true);

    Promise.allSettled([getArticleVariant(item.symbol_art), getArticleCrossSell(item.symbol_art)])
      .then(results => {
        const list = [[], [], []];

        if (results[0].value?.status == 0) {
          list[0] = parseJSON(results[0]?.value.dane);
        }

        if (results[1].value?.status == 0) {
          const crossList = parseJSON(results[1]?.value.dane);
          list[1] = crossList.filter(el => el.typ !== "W");
          list[2] = crossList.filter(el => el.typ === "W");
        }

        if (isMounted) {
          Array.isArray(list[0]) && setArticleVariantList(list[0]);
          Array.isArray(list[1]) && setArticleCrossList(list[1]);
          Array.isArray(list[2]) && setArticleCrossMultiList(list[2]);
        }

        if (list[0]?.length > 0 && list[0][0].tytul != undefined)
          tabList[3].title = list[0][0].tytul;

        if (list[1]?.length > 0 && list[1][0].tytul != undefined)
          tabList[4].title = list[1][0].tytul;

        if (list[2]?.length > 0 && list[2][0].tytul != undefined)
          tabList[5].title = list[2][0].tytul;

        if (isMounted) {
          setTabList([...tabList]);
        }
        // list.length = 0;
      }).finally(() => {
        if (isMounted) setLoading(false);
      });

    return () => {
      isMounted = false;
    };
  }, [item]);

  const hashCode = s => (""+s).split('').reduce((a,b)=>{a=((a<<5)-a)+b.charCodeAt(0);return a&a},0);

  return (
    <div className="w-full mt-2 tabs">
      <ul className="flex flex-col justify-center font-medium border-b border-gray-100 tab_header md:flex-row">
        {tabList.map((tab, index) => (
          <li key={`article_show_tab_${hashCode(tab.title) + index + 1}`} className={`${tab?.state == undefined || stateBag[tab.state].length > 0 ? 'block' : 'hidden'}`}>
            <a
              onClick={(e) => tabShow(e, index + 1)}
              className={`${tabNum == index + 1 ? 'border-primary text-primary' : ''} block px-8 py-2 border-b-2 border-transparent hover:border-b-2 hover:border-primary hover:text-primary hover:cursor-pointer text-center`}
              href={void(0)}
            >
              {tab.title}
            </a>
          </li>
        ))}
        {loading && (
          <li className='items-center'>
            <button className={`text-primary hover:cursor-pointer py-3 pb-2`}>
              <IconSpinner />
            </button>
          </li>
        )}
      </ul>
      <div className={`px-0 py-5 tab_content md:p-5 ${isPopup ? "max-h-60 overflow-y-auto" : ""}`}>
        <section className={`${tabNum == 1 ? 'block' : 'hidden'} transition duration-500 ease-in-out normalized`}>
          {ReactHtmlParser(item.opis_html)}
          {/* {ReactHtmlParser(item.opis_html || String(item.long_desc).replaceAll("\n", "<br/>").replaceAll("\t", "&nbsp;&nbsp;&nbsp;&nbsp;"))} */}
        </section>
        <section className={`${tabNum == 2 ? 'block' : 'hidden'} transition duration-500 ease-in-out`}>
          <FeatureList list={item?.cechy} handleCount={(count) => count > 0 && setArticleFeatureList([count])} />
        </section>
        <section className={`${tabNum == 3 ? 'block' : 'hidden'} transition duration-500 ease-in-out`}>
          <DocumentList symbol={item.symbol_art} handleCount={(count) => count > 0 && setArticleDocumentList([count])} />
        </section>
        <section className={`${tabNum == 4 ? 'block' : 'hidden'} transition duration-500 ease-in-out`}>
          {articleVariantList.length > 0 && (
            <ArticleGrid
              data={articleVariantList}
              handleSort={(key, direction) => false}
              handleUpdate={() => false}
              headerCartSelect={false}
              noDrag={true}
              noHeader={false}
              noVariant={true}
              noDialog={true}
            />
          )}
        </section>
        <section className={`${tabNum == 5 ? 'block' : 'hidden'} transition duration-500 ease-in-out`}>
          {articleCrossList.length > 0 && (
            <ArticleGrid
              data={articleCrossList}
              handleSort={(key, direction) => false}
              handleUpdate={() => false}
              headerCartSelect={false}
              // noDetail={true}
              noDrag={true}
              noHeader={false}
              noVariant={true}
              noDialog={true}
            />
          )}
        </section>
        <section className={`${tabNum == 6 ? 'block' : 'hidden'} transition duration-500 ease-in-out`}>
          {articleCrossMultiList.length > 0 && (
            <ArticleGrid
              data={articleCrossMultiList}
              handleSort={(key, direction) => false}
              handleUpdate={() => false}
              headerCartSelect={false}
              // noDetail={true}
              noDrag={true}
              noHeader={false}
              noVariant={true}
              noDialog={true}
            />
          )}
        </section>
      </div>
    </div>
  );
};

const ImageGallery = ({ item, imageCount = 0, isPopup = false }) => {
  const globalState = useContext(GlobalStateContext);
  const [state, cartAction] = useCartAction();
  const [imageCurr, setImageCurr] = useState(0);
  const [imageCurrSrc, setImageCurrSrc] = useState('');
  const refImageGallery = useRef();
  const [loadArticleImage] = useFileCache();

  const changeFavorite = (isOff) => {
    cartAction.setFavorite(item, isOff);
  };

  useEffect(() => {
    // console.log("IMAGE TEMPLATE");
  }, [imageCurr]);

  const showImage = (index) => {
    setImageCurr(index);
  };

  const scrollImageGallery = (direction) => {
    const amount = 92;

    // refImageGallery.current.children[0].style.transition = 'all .5s ease';
    refImageGallery.current.style.scrollBehavior = "smooth";
    // refImageGallery.current.style.scrollPadding = 0;

    // if (direction === "up")
    //   refImageGallery.current.children[0].style.transform = "translateY(-" + amount + "px)";

    // if (direction === "down")
    //   refImageGallery.current.children[0].style.transform = "translateY(" + amount + "px)";

    if (direction === "up")
      refImageGallery.current.scrollTop -= amount;

    if (direction === "down")
      refImageGallery.current.scrollTop += amount;
  };

  const showGallery = (e) => {
    e.preventDefault();
    const container = document.getElementById('show-modal');

    const closeModal = () => {
      unmountComponentAtNode(container);
    };

    const onChangeImage = (data) => {
      if (data.current >= 0 && data.current < imageCount) {
        setTimeout(() => {
          // closeModal();
          renderGallery(data.current);
        }, 0);
      }
    };

    const renderGallery = (current = 0) => {
      render(
        <GalleryDialog
          title="Informacja"
          open
          onClose={closeModal}
          onChange={onChangeImage}
          imageCurr={current}
          imageCount={imageCount}
        >
          <LazyImage
            refresh={Math.random()*100}
            className=""
            src=""
            symbol={item.symbol_art}
            width="1200"
            height="0"
            alt=""
            random={"gallery_full_" + (Math.random()*100)}
            handlerLoad={(symbol) => loadArticleImage(symbol, 1200, current)}
          />
        </GalleryDialog>,
        container,
      );
    };

    renderGallery(imageCurr);
  };

  return (
    <div className="flex">
      <div className="flex flex-col mr-4">
        {imageCount > 1 && (
          <>
            {imageCount > 4 && (
              <button
                onClick={() => scrollImageGallery("up")}
                className="flex justify-center w-full p-1 text-center rounded hover:border-primary hover:text-primary "
                type="button"
              >
                <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none">
                  <path d="M19 16L12 9L5 16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </button>
            )}
            <div
              ref={refImageGallery}
              className="relative flex flex-col my-1 overflow-hidden"
              style={{ maxHeight: isPopup ? '218px' : '368px' }}
            >
              <div className='flex flex-col'>
                {Array.from(Array(imageCount).keys()).map((img) => (
                  <button
                    onClick={() => showImage(img)}
                    key={`article_show_image_${img}`}
                    type="button"

                    className={`border-2 m-1 focus:outline-none ${imageCurr == img ? 'border-primary' : ''}`}
                  >
                    <picture>
                      <LazyImage
                        className=""
                        src=""
                        symbol={item.symbol_art}
                        width="80"
                        height="80"
                        alt=""
                        handlerLoad={(symbol) => loadArticleImage(symbol, 80, img)}
                      />
                    </picture>
                  </button>
                ))}
              </div>
            </div>
            {imageCount > 4 && (
              <button
                onClick={() => scrollImageGallery('down')}
                className="flex justify-center w-full p-1 text-center rounded hover:border-primary hover:text-primary"
                type="button"
              >
                <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none">
                  <path d="M19 9L12 16L5 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </button>
            )}
          </>
        )}
      </div>
      <picture className="relative transition duration-200 ease-in-out bg-white">
        <button
          type="button"
          onClick={showGallery}
          className="flex items-center h-full border border-dashed group"
        >
          <div className="relative" key={"image_main_"+ imageCurr}>
            {globalState.user?.favorite_display && (
              <FaviButton
                value={state.favorite[item.symbol_art]}
                onChange={changeFavorite}
                defaultClassName={`${'absolute top-1 right-1 z-10 p-1'}`}
              />
            )}
              <LazyImage
                className={`transition-opacity ease-in-out delay-150`}
                src=""
                srcLoad={imageCurrSrc}
                symbol={item.symbol_art}
                width="440"
                height="440"
                alt=""
                random={Math.random() * 100}
                handlerLoad={(symbol) => loadArticleImage(symbol, 440, imageCurr)}
                handlerDone={(imageSrc) => setImageCurrSrc(imageSrc)}
              />
          </div>
          {/* ICON search zoom */}
          <svg className='absolute z-10 text-gray-500 transition-all -translate-x-1/2 -translate-y-1/2 w-7 h-7 group-hover:text-primary group-hover:w-8 group-hover:h-8 -bottom-1 -right-1 ' viewBox="0 0 24 24" fill="none">
            <path d="M9.19995 11.7H14.2" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.7 14.2V9.19995" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z" 
                stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"
            />
            <path d="M22 22L20 20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </button>
      </picture>
    </div>
  );
};

const StickerGallery = ({ symbol, imageCount = 0 }) => {
  const [,,,,loadArticleSticker] = useFileCache();

  if (imageCount == 0) {
    return '';
  }

  return (
    <div className="flex">
      <div className="flex flex-row space-x-4">
        {Array.from(Array(imageCount).keys()).map((img, index) => (
          <picture key={`sticker_${index}`}>
            <LazyImage
              className=""
              src=""
              symbol={symbol}
              width="60"
              height="60"
              alt=""
              handlerLoad={(symbol) => loadArticleSticker(symbol, 60, img)}
            />
          </picture>
        ))}
      </div>
    </div>
  );
};

const ImagesPack = ({ symbol }) => {
  const [loading, setLoading] = useState(false);

  const getImagesPack = async () => {
    setLoading(true);
    await getArticleImagesPack(symbol);
    setLoading(false);
    return true;
  };

  return (
    <button
      type="button"
      className={`relative inline-flex items-center bg-white border border-gray-300 rounded-md group 
        hover:border-primary h-9 ${loading ? 'rainbow-box' : ''}`}
      onClick={getImagesPack}
    >
      <span className="pl-3">
        <svg className="w-6 h-8 group-hover:text-primary" viewBox="0 0 48 48" style={{ enableBackground: "new 0 0 48 48"}} fill="currentColor">
          <g>
            <path d="M47.987,21.938c-0.006-0.091-0.023-0.178-0.053-0.264c-0.011-0.032-0.019-0.063-0.033-0.094
              c-0.048-0.104-0.109-0.202-0.193-0.285c-0.001-0.001-0.001-0.001-0.001-0.001L42,15.586V10c0-0.022-0.011-0.041-0.013-0.063
              c-0.006-0.088-0.023-0.173-0.051-0.257c-0.011-0.032-0.019-0.063-0.034-0.094c-0.049-0.106-0.11-0.207-0.196-0.293l-9-9
              c-0.086-0.086-0.187-0.148-0.294-0.196c-0.03-0.014-0.06-0.022-0.091-0.032c-0.085-0.03-0.172-0.047-0.263-0.052
              C32.039,0.01,32.021,0,32,0H7C6.448,0,6,0.448,6,1v14.586l-5.707,5.707c0,0-0.001,0.001-0.002,0.002
              c-0.084,0.084-0.144,0.182-0.192,0.285c-0.014,0.031-0.022,0.062-0.033,0.094c-0.03,0.086-0.048,0.173-0.053,0.264
              C0.011,21.96,0,21.978,0,22v19c0,0.552,0.448,1,1,1h5v5c0,0.552,0.448,1,1,1h34c0.552,0,1-0.448,1-1v-5h5c0.552,0,1-0.448,1-1V22
              C48,21.978,47.989,21.96,47.987,21.938z M44.586,21H42v-2.586L44.586,21z M38.586,9H33V3.414L38.586,9z M8,2h23v8
              c0,0.552,0.448,1,1,1h8v5v5H8v-5V2z M6,18.414V21H3.414L6,18.414z M40,46H8v-4h32V46z M46,40H2V23h5h34h5V40z"
            />
            <polygon points="14.582,27.766 18.356,27.766 14.31,36.317 14.31,38 20.6,38 20.6,36.164 16.571,36.164 20.6,27.613 20.6,25.964 
              14.582,25.964"
            />
            <rect x="22.436" y="25.964" width="2.04" height="12.036" />
            <path d="M32.542,26.72c-0.323-0.277-0.688-0.473-1.097-0.586c-0.408-0.113-0.805-0.17-1.19-0.17h-3.332V38h2.006v-4.828h1.428
              c0.419,0,0.827-0.074,1.224-0.221c0.397-0.147,0.748-0.374,1.054-0.68c0.306-0.306,0.553-0.688,0.739-1.148
              c0.187-0.459,0.28-0.994,0.28-1.606c0-0.68-0.105-1.247-0.314-1.7C33.132,27.364,32.866,26.998,32.542,26.72z M31.259,31.005
              c-0.306,0.334-0.697,0.501-1.173,0.501H28.93v-3.825h1.156c0.476,0,0.867,0.147,1.173,0.442c0.306,0.295,0.459,0.765,0.459,1.411
              C31.718,30.18,31.565,30.67,31.259,31.005z"
            />
          </g>
        </svg>
      </span>
      <span className="px-2 pr-3 text-sm font-medium leading-4 text-gray-700 group-hover:text-primary">pobierz zdjęcia</span>
    </button>
  );
};

const PriceTemplate = ({ item }) => {
  const globalStateQ = useContext(GlobalStateContext);
  const [cartState, cartAction] = useCartAction();
  const { t } = useTranslation("shop");

  const defaultJM = item.jm_pom.find(el => el.podstawowa === 1);
  const [selectedJM, setSelectedJM] = useState(defaultJM);

  const [showCalcPrice, setShowCalcPrice] = useState(false);
  const [showBranchCalcPrice, setShowBranchCalcPrice] = useState(false);
  const [quantity, setQuantity] = useState({ ...cartAction.getProduct({ item }) });

  useEffect(() => {
    // console.log('currency', String(+item.cena * parseFloat(selectedJM.przelicz)), item.cena, parseFloat(selectedJM.przelicz));

    const jm = item.jm_pom.find(el => el.symbol_jmp === cartState.measure[item.symbol_art]);
    if (jm !== undefined) setSelectedJM(jm);

    setQuantity({ ...cartAction.getProduct({ item }) });
  }, [cartState.measure[item.symbol_art], cartState.select]);

  const updateCalcPrice = (price) => {
    setShowCalcPrice(false);
    if (price <= 0) return;

    cartAction.setProduct({
      item,
      symbolJM: selectedJM.symbol_jmp,
      quantity,
      // force: true, /* force */
      price, /* newPrice */
    });

    setQuantity({ ...cartAction.getProduct({ item }) });
  };

  const updateBranchCalcPrice = (price) => {
    setShowBranchCalcPrice(false);
    if (price <= 0) return;

    // cartAction.setProduct({
    //   item,
    //   symbolJM: selectedJM.symbol_jmp,
    //   quantity,
    //   // force: true, /* force */
    //   price, /* newPrice */
    // });

    // setQuantity({ ...cartAction.getProduct({ item }) });
  };

  return (
    <>
      <div className="flex flex-col">
        <div className="flex items-center mb-0">
          <div className="mt-1 mr-2 text-gray-500 first-letter:uppercase">
            {(!!globalStateQ.user?.price_brutto ? t('cena brutto') : t('cena netto'))}
          </div>
          <div className="flex flex-row items-center justify-start flex-grow space-x-2" >
            {+item.cena_nom > 0 && Number.parseFloat(+item.cena_nom).toFixed(2) != +item.cena && (
              <span className={`block text-xs text-red-400 leading-3 text-right items-right pr-8`} translate="0">
                {numberFormatCurrency(+item.cena_nom, item.currency)}
              </span>
            )}
            {globalStateQ.user?.price_catalog === true && +item?.base_price > 0 && roundTo(+item.base_price * parseFloat(selectedJM.przelicz), 2) > roundTo((+item.cena || (!!globalStateQ.user?.price_brutto ? +item.cena_b : +item.cena_n)) * parseFloat(selectedJM.przelicz), 2) && (
              <>
                {globalStateQ.user?.rabat_procent === true && (
                  <span className='block mt-1 text-sm leading-3 text-red-400'>
                    {/* {roundTo(100 - (((+item.cena || item.cena_n) * 100) / (+item.base_price)), 2)}% */}
                    {roundTo(100 * ((+item.base_price - (+item.cena || (!!globalStateQ.user?.price_brutto ? +item.cena_b : +item.cena_n))).toFixed(2) / +item.base_price).toFixed(2), 2)}%
                  </span>
                )}
                {globalStateQ.user?.rabat_procent !== true && (
                  <span className='block mt-1 text-sm leading-3 text-red-400 line-through'>
                    {numberFormatCurrency(+item.base_price * parseFloat(selectedJM.przelicz), item.currency)}
                  </span>
                )}
              </>
            )}
            <span className={`${(quantity.price_discount > 0 && roundTo(+quantity.price_discount, 2) != roundTo(item.cena, 2)) ? '' : 'text-red-400'} 
              block text-xl md:whitespace-nowrap`} translate="0"
            >
              {+item.cena ?
              +item.cena !== 0 ? numberFormatCurrency((+item.cena) * parseFloat(selectedJM.przelicz), item.currency) : '-,--'
              : numberFormatCurrency((!!globalStateQ.user?.price_brutto ? +item.cena_b : +item.cena_n) * parseFloat(selectedJM.przelicz), item.currency)}&nbsp;/&nbsp;{selectedJM.symbol_jmp}
            </span>
            <span className={`${selectedJM.podstawowa !== 1 ? '' : 'hidden'} text-xs text-gray-400 leading-3 block mt-1 md:whitespace-nowrap`} translate="0">
              {+item.cena !== 0 ? numberFormatCurrency(+item.cena, item.currency) : '-,--'}&nbsp;/&nbsp;{defaultJM && defaultJM?.symbol_jmp}
            </span>
            {!!globalStateQ.user?.is_han && globalStateQ.user?.price_change === true && (item.zapas?.actual > 0 || globalStateQ.user?.stock_unlimit == 1) && (
              <>
                {/* Calc Price */}
                <button
                  type="button"
                  className='w-4 h-4 outline-none focus:outline-none'
                  onClick={() => setShowCalcPrice(true)}
                  data-tooltip="edytuj cenę"
                >
                  {/* ICON edit price */}
                  <svg
                    className={`${showCalcPrice == true && 'hidden'}`}
                    viewBox="0 0 424 424"
                    style={{ fillRule: "evenodd", clipRule: "evenodd", strokeLinejoin: "round", strokeMiterlimit:2 }}
                  >
                    <g>
                      <path style={{fill: "none", fillRule: "nonzero"}} d="M186.95,343.077L147.583,354.439L294.251,354.439C300.88,354.439 306.251,359.812 306.251,366.439C306.251,373.066 300.88,378.439 294.251,378.439L66.541,378.439C65.898,378.439 65.273,378.374 64.658,378.276C64.015,378.381 63.371,378.439 62.73,378.439C59.732,378.439 56.798,377.315 54.546,375.217C51.46,372.34 50.111,368.054 50.993,363.929L78.927,233.331C79.411,231.067 80.54,228.992 82.177,227.355L228.255,81.277L26.412,81.277L26.412,399.278L344.413,399.278L344.413,187.728L192.108,340.033C190.673,341.467 188.899,342.514 186.95,343.077Z" />
                      <path style={{fill: "none", fillRule: "nonzero"}} d="M163.321,324.917L97.879,259.474L78.654,349.352L163.321,324.917Z" />
                      <path style={{fill: "none", fillRule: "nonzero"}} d="M327.089,24C322.167,24 318.161,25.6 314.485,29.035C314.34,29.172 314.192,29.303 314.042,29.43L305.376,38.096L382.739,115.458L391.21,106.988C391.251,106.946 391.294,106.906 391.335,106.865L391.421,106.78C391.544,106.635 391.671,106.492 391.802,106.352C407.984,89.034 380.118,58.569 371.193,49.645C354.897,33.346 338.821,24 327.089,24Z" />
                      <path style={{fill: "#00C447", fillRule: "nonzero"}} d="M107.91,235.564L185.272,312.926L365.768,132.428L288.405,55.065L107.91,235.564ZM309.38,111.454C314.066,116.14 314.066,123.738 309.38,128.424L182.606,255.197C180.262,257.539 177.192,258.711 174.12,258.711C171.05,258.711 167.979,257.54 165.636,255.197C160.95,250.51 160.95,242.912 165.636,238.226L292.409,111.454C297.095,106.768 304.694,106.768 309.38,111.454Z"/>
                      <path style={{fill: "#4B5563", fillRule: "nonzero"}} d="M165.636,255.197C167.98,257.54 171.05,258.711 174.12,258.711C177.192,258.711 180.262,257.539 182.606,255.197L309.38,128.424C314.066,123.737 314.066,116.139 309.38,111.454C304.695,106.768 297.095,106.768 292.409,111.454L165.636,238.226C160.95,242.913 160.95,250.51 165.636,255.197Z" />
                      <path style={{fill: "#4B5563", fillRule: "nonzero"}} d="M417.848,76.476C413.213,62.604 402.95,47.457 388.162,32.673C373.259,17.768 350.565,0 327.089,0C316.558,0 306.855,3.703 298.954,10.721C298.542,11.037 298.149,11.382 297.778,11.753L252.253,57.277L14.412,57.277C7.785,57.277 2.412,62.65 2.412,69.277L2.412,411.278C2.412,417.905 7.785,423.278 14.412,423.278L356.413,423.278C363.04,423.278 368.413,417.905 368.413,411.278L368.413,166.123C368.413,165.375 368.335,164.647 368.204,163.937L408.057,124.083C408.137,124.004 408.217,123.926 408.295,123.846C408.297,123.845 408.299,123.843 408.301,123.841L409.082,123.059C409.451,122.69 409.789,122.305 410.099,121.902C421.012,109.661 423.694,93.979 417.848,76.476ZM163.321,324.917L78.654,349.353L97.879,259.475L163.321,324.917ZM185.272,312.926L107.91,235.564L288.405,55.066L365.768,132.429L185.272,312.926ZM344.413,399.278L26.412,399.278L26.412,81.277L228.255,81.277L82.178,227.355C80.541,228.992 79.412,231.067 78.928,233.331L50.994,363.928C50.111,368.053 51.461,372.339 54.547,375.216C56.799,377.314 59.733,378.438 62.731,378.438C63.219,378.438 62.731,378.438 62.731,378.438C65.994,378.438 104.729,368 101,368L147.583,354.438L186.95,343.076C188.899,342.513 190.673,341.466 192.108,340.032L344.413,187.727L344.413,399.278ZM391.802,106.351C391.671,106.491 391.544,106.634 391.421,106.779L391.335,106.864C391.294,106.905 391.251,106.946 391.21,106.987L382.739,115.457L305.376,38.095L314.042,29.429C314.192,29.302 314.341,29.171 314.485,29.034C318.161,25.6 322.167,24 327.089,24C338.821,24 354.898,33.346 371.192,49.644C380.118,58.568 407.983,89.033 391.802,106.351Z" />
                    </g>
                  </svg>
                  <svg className={`${showCalcPrice != true && 'hidden'} animate-spin text-primary`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-50" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                    <path fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                  </svg>
                </button>
                {showCalcPrice == true && (
                  <CalcProduct
                    title={item.label}
                    quantity={quantity}
                    handleClose={() => setShowCalcPrice(false)}
                    handleUpdate={(_, price) => updateCalcPrice(price)}
                  />
                )}
              </>
            )}
            {!!globalStateQ.user?.is_han && globalStateQ.user?.price_change === true && globalStateQ.user?.branch_show === true && globalStateQ.user?.branch_price === true && (
              <>
                {/* Branch Calc Price */}
                <button
                  type="button"
                  className='w-4 h-4 outline-none focus:outline-none'
                  onClick={() => setShowBranchCalcPrice(true)}
                  data-tooltip="edytuj cennik oddziału"
                >
                  {/* ICON edit price */}
                  <svg
                    className={`${showBranchCalcPrice == true && 'hidden'}`}
                    viewBox="0 0 424 424"
                    style={{ fillRule: "evenodd", clipRule: "evenodd", strokeLinejoin: "round", strokeMiterlimit:2 }}
                  >
                    <g>
                      <path style={{fill: "none", fillRule: "nonzero"}} d="M186.95,343.077L147.583,354.439L294.251,354.439C300.88,354.439 306.251,359.812 306.251,366.439C306.251,373.066 300.88,378.439 294.251,378.439L66.541,378.439C65.898,378.439 65.273,378.374 64.658,378.276C64.015,378.381 63.371,378.439 62.73,378.439C59.732,378.439 56.798,377.315 54.546,375.217C51.46,372.34 50.111,368.054 50.993,363.929L78.927,233.331C79.411,231.067 80.54,228.992 82.177,227.355L228.255,81.277L26.412,81.277L26.412,399.278L344.413,399.278L344.413,187.728L192.108,340.033C190.673,341.467 188.899,342.514 186.95,343.077Z" />
                      <path style={{fill: "none", fillRule: "nonzero"}} d="M163.321,324.917L97.879,259.474L78.654,349.352L163.321,324.917Z" />
                      <path style={{fill: "none", fillRule: "nonzero"}} d="M327.089,24C322.167,24 318.161,25.6 314.485,29.035C314.34,29.172 314.192,29.303 314.042,29.43L305.376,38.096L382.739,115.458L391.21,106.988C391.251,106.946 391.294,106.906 391.335,106.865L391.421,106.78C391.544,106.635 391.671,106.492 391.802,106.352C407.984,89.034 380.118,58.569 371.193,49.645C354.897,33.346 338.821,24 327.089,24Z" />
                      <path style={{fill: "#f97316", fillRule: "nonzero"}} d="M107.91,235.564L185.272,312.926L365.768,132.428L288.405,55.065L107.91,235.564ZM309.38,111.454C314.066,116.14 314.066,123.738 309.38,128.424L182.606,255.197C180.262,257.539 177.192,258.711 174.12,258.711C171.05,258.711 167.979,257.54 165.636,255.197C160.95,250.51 160.95,242.912 165.636,238.226L292.409,111.454C297.095,106.768 304.694,106.768 309.38,111.454Z"/>
                      <path style={{fill: "#4B5563", fillRule: "nonzero"}} d="M165.636,255.197C167.98,257.54 171.05,258.711 174.12,258.711C177.192,258.711 180.262,257.539 182.606,255.197L309.38,128.424C314.066,123.737 314.066,116.139 309.38,111.454C304.695,106.768 297.095,106.768 292.409,111.454L165.636,238.226C160.95,242.913 160.95,250.51 165.636,255.197Z" />
                      <path style={{fill: "#4B5563", fillRule: "nonzero"}} d="M417.848,76.476C413.213,62.604 402.95,47.457 388.162,32.673C373.259,17.768 350.565,0 327.089,0C316.558,0 306.855,3.703 298.954,10.721C298.542,11.037 298.149,11.382 297.778,11.753L252.253,57.277L14.412,57.277C7.785,57.277 2.412,62.65 2.412,69.277L2.412,411.278C2.412,417.905 7.785,423.278 14.412,423.278L356.413,423.278C363.04,423.278 368.413,417.905 368.413,411.278L368.413,166.123C368.413,165.375 368.335,164.647 368.204,163.937L408.057,124.083C408.137,124.004 408.217,123.926 408.295,123.846C408.297,123.845 408.299,123.843 408.301,123.841L409.082,123.059C409.451,122.69 409.789,122.305 410.099,121.902C421.012,109.661 423.694,93.979 417.848,76.476ZM163.321,324.917L78.654,349.353L97.879,259.475L163.321,324.917ZM185.272,312.926L107.91,235.564L288.405,55.066L365.768,132.429L185.272,312.926ZM344.413,399.278L26.412,399.278L26.412,81.277L228.255,81.277L82.178,227.355C80.541,228.992 79.412,231.067 78.928,233.331L50.994,363.928C50.111,368.053 51.461,372.339 54.547,375.216C56.799,377.314 59.733,378.438 62.731,378.438C63.219,378.438 62.731,378.438 62.731,378.438C65.994,378.438 104.729,368 101,368L147.583,354.438L186.95,343.076C188.899,342.513 190.673,341.466 192.108,340.032L344.413,187.727L344.413,399.278ZM391.802,106.351C391.671,106.491 391.544,106.634 391.421,106.779L391.335,106.864C391.294,106.905 391.251,106.946 391.21,106.987L382.739,115.457L305.376,38.095L314.042,29.429C314.192,29.302 314.341,29.171 314.485,29.034C318.161,25.6 322.167,24 327.089,24C338.821,24 354.898,33.346 371.192,49.644C380.118,58.568 407.983,89.033 391.802,106.351Z" />
                    </g>
                  </svg>
                  <svg className={`${showBranchCalcPrice != true && 'hidden'} animate-spin text-primary`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-50" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                    <path fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                  </svg>
                </button>
                {showBranchCalcPrice == true && (
                  <BranchCalcProduct
                    title={item.label}
                    quantity={quantity}
                    handleClose={() => setShowBranchCalcPrice(false)}
                    handleUpdate={(_, price) => updateBranchCalcPrice(price)}
                  />
                )}
              </>
            )}
          </div>
        </div>
        <div className="flex items-center mb-3">
          {quantity.price_discount > 0 && roundTo(+quantity.price_discount, 2) != roundTo(item.cena, 2) && (
            <>
              <div className="flex items-center gap-1 mt-1 mr-2 text-gray-500">
                <IconDiscount size="5" />
                <span className='first-letter:uppercase text-red-400'>{(!!globalStateQ.user?.price_brutto ? t('cena brutto') : t('cena netto'))}:</span>
              </div>
              <div className="flex flex-row items-center justify-start flex-grow space-x-2">
                <span
                  className="block text-xl text-red-400 md:whitespace-nowrap"
                  data-price-discount={quantity.price_discount}
                  data-price-price={item.cena || +item.cena_n}
                >
                  {numberFormatCurrency(+quantity.price_discount * parseFloat(selectedJM.przelicz), item.currency)}&nbsp;/&nbsp;{selectedJM.symbol_jmp}
                  &nbsp;
                  {/* ({100 - roundTo((+quantity.price_discount * parseFloat(selectedJM.przelicz) * 100) / ((+item.cena || +item.cena_n) * parseFloat(selectedJM.przelicz)), 0)}%) */}
                  {(+item.cena || (!!globalStateQ.user?.price_brutto ? +item.cena_b : +item.cena_n)) < +quantity.price_discount && (
                    <>
                      (-{Math.abs(roundTo(100 * (((+quantity.price_discount) - (+item.cena || (!!globalStateQ.user?.price_brutto ? +item.cena_b : +item.cena_n))).toFixed(2) / (+quantity.price_discount).toFixed(2)), 0))}%)
                    </>
                  )}
                  {(+item.cena || (!!globalStateQ.user?.price_brutto ? +item.cena_b : +item.cena_n)) > +quantity.price_discount && (
                    <>
                      (-{Math.abs(roundTo(100 * (((+item.cena || (!!globalStateQ.user?.price_brutto ? +item.cena_b : +item.cena_n)) - (+quantity.price_discount)).toFixed(2) / (+item.cena || (!!globalStateQ.user?.price_brutto ? +item.cena_b : +item.cena_n)).toFixed(2)), 0))}%)
                    </>
                  )}
                </span>
                <span className={`${selectedJM.podstawowa !== 1 ? '' : 'hidden'} text-xs text-gray-400 leading-3 block mt-1 md:whitespace-nowrap`}>
                  {numberFormatCurrency(+quantity.price_discount, item.currency)}&nbsp;/&nbsp;{defaultJM && defaultJM?.symbol_jmp}
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const QuantityPackageTemplate = ({ item }) => {
  const { t } = useTranslation("shop");

  const [state] = useCartAction();
  const defaultJMP = item.jm_pom.find(el => el.symbol_jmp === state.measure[item.symbol_art]);

  return (
    <div data-jmjedn={`${""+item.ilosc_jedn}`} className="text-sm">
      <span className="text-gray-500">{t('Ilość w opakowaniu')}:</span> {numberFormatQuantity(item.ilosc_jedn || 0, +defaultJMP?.precision)}&nbsp;{item.jm_jedn}
    </div>
  );
}

const QuantityTemplate = ({ item }) => {
  const { t } = useTranslation("shop");

  const defaultJMD = item.jm_pom.sort((a, _) => a.podstawowa == 1 && a.visible == 1 ? -1 : 1).find(el => el.visible == 1);
  if (defaultJMD === undefined) return ''; // prevent reload

  const showQuantityInfo = item?.outOfStock != undefined && Array.isArray(item.outOfStock) && item.outOfStock[0]?.value != undefined && item.outOfStock[0].value.trim() != "";
  const tplQuantityInfo = (
    <span className='block text-sm leading-3 text-red-400'>
      {item?.outOfStock != undefined && Array.isArray(item.outOfStock) && (item.outOfStock[0].desc.trim() || item.outOfStock[0].value.trim())}
    </span>
  );

  if (item.zapas?.stock_show == 2) { // dostępny/niedostępny
    return (
      <span className="block text-sm" key="quantity_available_0">
        {item.zapas.actual == 1 ? t('dostępny') : showQuantityInfo ? tplQuantityInfo : t('niedostępny')}
      </span>
    );
  }

  if (item.zapas?.stock_show == 3) { // pasek graficzny
    return (
      <>
        {(item.zapas.actual <= 0 && showQuantityInfo) && tplQuantityInfo}
        {!(item.zapas.actual <= 0 && showQuantityInfo) && (
          <Battery value={item.zapas.actual} min={item.zapas.min} max={item.zapas.max} key="quantity_battery_0" />
        )}
      </>
    );
  }

  const [state] = useCartAction();
  const basicJM = item.jm_pom.find(el => el.podstawowa === 1);
  const defaultJMP = item.jm_pom.find(el => el.symbol_jmp === state.measure[item.symbol_art]);

  const [selectedJM, setSelectedJM] = useState(defaultJMP || defaultJMD);

  useEffect(() => {
    const jm = item.jm_pom.find(el => el.symbol_jmp === state.measure[item.symbol_art]);
    if (jm !== undefined) setSelectedJM(jm);
  }, [state.measure[item.symbol_art], state.select]);

  return (
    <div className="flex flex-row items-center mb-1 space-x-2">
      <div className="mr-2 text-base text-gray-500">{t('Zapas')}:</div>
      {(item.zapas.actual <= 0 && showQuantityInfo) && tplQuantityInfo}
      {!(item.zapas.actual <= 0 && showQuantityInfo) && (
        <>
          <span key="quantity_real_0" data-precision={+selectedJM?.precision}>
            {numberFormatQuantity(item.zapas.actual / (selectedJM?.przelicz || 1), +selectedJM?.precision)}&nbsp;{selectedJM.symbol_jmp}
          </span>
          {selectedJM?.podstawowa !== 1 && (
            <span className="block text-xs leading-3 text-gray-400">{numberFormatQuantity(item.zapas.actual, +selectedJM?.precision)}&nbsp;{basicJM && t(basicJM?.symbol_jmp)}</span>
          )}
        </>
      )}
    </div>
  );
};

const CartTemplate = ({ item }) => {
  const defaultJM = item.jm_pom.sort((a, _) => a.podstawowa == 1 && a.visible == 1 ? -1 : 1).find(el => el.visible == 1);
  if (defaultJM === undefined) return ''; // prevent reload

  const [state, cartAction] = useCartAction();
  const [quantity, setQuantity] = useState({ ...cartAction.getProduct({ item }) });
  const [count, setCount] = useState(cartAction.getProductConfirm(item));

  const globalStateQ = useContext(GlobalStateContext);
  
  const jmList = item.jm_pom
  .filter(el => el.visible == 1)
  .map(el => ({ key: el.symbol_jmp, value: el.nazwa_jm, sort: el.podstawowa }))
  .sort((a, b) => a.sort + b.sort);
  
  const basicJM = item.jm_pom.find(el => el.podstawowa === 1);
  const defaultJMP = item.jm_pom.find(el => el.symbol_jmp === state.measure[item.symbol_art]);
  
  const [selectedJM, setSelectedJM] = useState(defaultJMP || defaultJM);
  const [firstRender, setFirstRender] = useState(false);
  const [quantityMax, setQuantityMax] = useState({ current: 0, actual: 0 });

  useEffect(() => {
    // if (firstRender === false) {
    //   return false;
    // }
    // console.log('product Effect', quantity);
    cartAction.setProduct({ item, quantity }).then(() => {
      setQuantityMax(cartAction.getQuantityMax({
        item,
        maxActual: item.zapas.actual,
        jmCurrent: defaultJM.symbol_jmp,
        priorityMeasure: true,
        stock_unlimit: globalStateQ.user?.stock_unlimit,
      }));
      setCount(cartAction.getProductConfirm(item));
    });
  }, [quantity]);

  useEffect(() => {
    if (firstRender === false) {
      return false;
    }
    // console.log('confirm Effect');
    setCount(cartAction.getProductConfirm(item));
  }, [state.select]);

  useEffect(() => {
    if (firstRender === false) {
      setFirstRender(true);
      return false;
    }
    // console.log('measure Effect');

    setQuantity({
      ...quantity,
      confirm: parseFloat(cartAction.getProduct({ item }).confirm),
      convert: parseFloat(cartAction.getProduct({ item }).convert),
      precision: parseFloat(cartAction.getProduct({ item }).precision),
    });
  }, [state.measure[item.symbol_art], state.select]);

  const changeFavorite = (isOff) => {
    cartAction.setFavorite(item, isOff);
  };

  const changeMeasure = (key) => {
    cartAction.setMeasure(item, key);
    setSelectedJM(item.jm_pom.find(el => el.symbol_jmp === key));
  };

  return (
    <CartContext.Provider value={[quantity, setQuantity, state.loading, state.error, state.done]}>
      <div className="flex flex-col items-center gap-2 xs:items-start xs:flex-row">
        {(item.jm_pom.length > 1 || (selectedJM?.podstawowa != undefined && selectedJM?.podstawowa !== 1)) && (
          <div className={`flex flex-col leading-5 ${item.jm_pom.length > 1 ? 'pr-2' : ''}`}>
            {item.jm_pom.length > 1 && (
              <SSelect
                className='py-1 pl-2 pr-8 text-sm leading-5 border rounded-md h-9'
                value={jmList}
                defaultValue={selectedJM?.symbol_jmp || defaultJMP || defaultJM}
                handleChange={changeMeasure}
              />
            )}
            {selectedJM?.podstawowa != undefined && selectedJM?.podstawowa !== 1 && (
              <span className="block text-xs text-gray-400">
                {`1${selectedJM.symbol_jmp}`}
                &nbsp;=&nbsp;
                {selectedJM.przelicz}
                {item.symbol_jm}
              </span>
            )}
          </div>
        )}
        <InputCounter
          value={1}
          defValue={1}
          max={quantityMax.current}
          maxActual={quantityMax.actual}
          disable={item.isabstractmodel || item.cena == 0}
        />
        <div className="flex flex-col">
          <CartButton
            value={1}
            max={quantityMax.current}
            maxActual={quantityMax.actual}
            disable={item.isabstractmodel || item.cena == 0}
            defaultClassName={`${count > 0 ? 'h-7' : 'h-9'}`}
            type="big"
            templateCount={(
              <span className={`${count > 0 ? '' : 'hidden'} flex-row block text-xs text-gray-400 leading-3 text-center relative`}>
                {count}&nbsp;{basicJM && basicJM?.symbol_jmp}
              </span>
            )}
          />
        </div>
      </div>
    </CartContext.Provider>
  );
};

const CartSelectTemplate = (item) => (
  <div className="flex flex-row items-center">
    <CartSelect styleSize="tiny" />
  </div>
);

const getArticleFiles = async (symbol) => {
  const resp = await api.getArticleFiles(symbol, 'Z');
  return resp?.data;
};


/**
 * MAIN
 */
const ArticleShow = (props) => {
  const globalState = useContext(GlobalStateContext);
  const params = useParams();
  const symbol = decodeURIComponent(params?.symbol || props?.symbol);
  const { t } = useTranslation("shop");

  let isMounted = false;

  if (symbol == undefined) return (
    <main className="w-full px-6 py-4">
      <div className="relative"> 
        <Alert content={'Brak artykułu'} color="yellow" />
      </div>
    </main>
  );

  const [noMenu] = useState(props?.isPopup == true)
  const [article, setArticle] = useState(null);
  const [articleFiles, setArticleFiles] = useState([]);
  const [articleStickers, setArticleStickers] = useState(0);
  const [pathTree, setPathTree] = useState([]);
  const [notFound, setNotFound] = useState(symbol == undefined);
  const [nodeKey, setNodeKey] = useState(['']);

  const [loading, setLoading] = useState(false);

  const handleCategory = (categoryList) => {
    // if (Array.isArray(categoryList) && categoryList[0] != '') {
    //   globalContext.setCategory(categoryList[0]);
    // }
  };

  const [MenuTreeRender, menuTreePath] = noMenu ? ['', () => ''] :useMenuTree({ handleCategory, newNode: nodeKey });

  useEffect(() => {
    if (symbol == undefined) return;
    isMounted = true;

    setLoading(true);
    setArticle(null);
    setArticleFiles([]);
    setArticleStickers(0);

    Promise.allSettled([getArticleFiles(symbol), getArticle(symbol), getArticleStickerCount(symbol)])
      .then(responses => {
        if (!isMounted) return;

        if (responses[0].value?.status === 0) {
          let files = parseJSON(responses[0].value.dane);
          if (!Array.isArray(files))
            files = [];

          setArticleFiles([ ...files ]);
        }

        if (responses[1].value?.status === 0) {
          const art = parseJSON(responses[1].value.dane);
          console.log('ARTICLE', art);

          if (art == undefined || (Array.isArray(art) && art.length === 0))
            setNotFound(true);
          else
            setArticle(art[0]);
        } else {
          setNotFound(true);
        }

        if (responses[2].value?.status === 0) {
          setArticleStickers(responses[2].value?.count);
        }
      }).finally(() => {
        isMounted && setLoading(false);
      });
    
    return () => {
      isMounted = false;
    }
  }, [symbol, globalState?.user]);

  useEffect(() => {
    if (article == null || noMenu == true) return;

    const nodeKeyArticle = Array.isArray(article.node_key) ? article.node_key[0] : article.node_key;
    setNodeKey([nodeKeyArticle]);

    menuTreePath([nodeKeyArticle]).then(menuTree => {
      const tree = Object.fromEntries(
        Object.entries(menuTree)
          .map(([key, val]) => {
            // console.log('FFFFF', key, val);
            return [key, '/article' + val];
          }),
      );

      console.log("TREE", tree, nodeKeyArticle);
      setPathTree(Object.assign({}, { 'Zakupy': '/article', ...tree}));
    });
  }, [article]);

  const countImages = () => {
    return articleFiles.length;
    // return articleFiles.reduce((cum, el) => cum + el.zdjecia.length, 0);
  }

  const countStickers = () => {
    return articleStickers;
  }

  return (
    <>
      {noMenu == false && (
        <MenuSide>
          <div>
            {MenuTreeRender}
          </div>
        </MenuSide>
      )}
      <main className="w-full px-6 py-4">
        <div className="relative"> 
          {loading && (
            <div className='py-4'>
              <SpinnerFixed />
            </div>
          )}
          {(notFound) && <Alert content={'Brak artykułu'} color="yellow" />}
          {(article !== null && article !== undefined) && (
            <>
              <div className="flex flex-col items-center pb-2 mb-4 border border-t-0 border-l-0 border-r-0 border-gray-200 md:flex-row border-b-1">
                {Object.keys(pathTree).length > 0 && (
                  <div className="flex-grow mb-4 breadcrumps md:mb-0">
                    <BreadCrumbs path={pathTree} />
                  </div>
                )}
                <div className="items-end flex-shrink">
                  <CartSelectTemplate item={article} />
                </div>
              </div>
              <div className="flex flex-col justify-center md:flex-row">
                <ImageGallery item={article} imageCount={countImages()} isPopup={!!props?.isPopup} />
                <div className="flex flex-col mt-4 items-top justify-top md:ml-4 md:mt-0">
                  <h2 data-symbol={article.symbol_art} className={`${article?.extra_field != undefined ? "" :"mb-5"}`}>
                    {/* <LabelTag data={article.wyroznienia.find(Boolean)} /> */}
                    <div>
                      {parser.toReact(article.nazwa_art)}
                      {article.label.length > 0 && (
                        <div className="text-sm">
                          <span className="text-gray-500">{t(article?.label_name) || "Symbol"}:</span> {article.label}
                        </div>
                      )}
                    </div>
                  </h2>
                  {article?.extra_field != undefined && (
                    <h3 data-extrafield={`${""+article.extra_field}`} className={`text-sm`}>
                      <span className="text-gray-500">{t(article.extra_field_name)}:</span> {article.extra_field}
                    </h3>
                  )}
                  {article?.ilosc_jedn > 0 && (
                    <QuantityPackageTemplate item={article} />
                  )}
                  {article?.czas_dst > 0 && article?.czas_dst < 999 && (
                    <div data-czasdst={`${""+article.czas_dst}`} className="text-sm">
                      <span className="text-gray-500">{t('Czas dostawy')}:</span> {article.czas_dst}&nbsp;{t('dni')}
                    </div>
                  )}
                  <div className='mb-5' />
                  <div className="flex flex-row">
                    <QuantityTemplate item={article} />
                  </div>
                  <div className="flex flex-row mb-1">
                    <PriceTemplate item={article} />
                  </div>
                  <div className="flex flex-col mb-3">
                    <CartTemplate item={article} />
                  </div>
                  <div className="flex flex-row mb-3 empty:hidden">
                    <ArticleProperties item={article} />
                  </div>
                  <div className="flex flex-row mb-3 empty:hidden">
                    <StickerGallery symbol={article.symbol_art} imageCount={countStickers()} />
                  </div>
                  {!!globalState.user?.is_han && globalState.user?.price_change === true && (
                    <div className="flex flex-row mb-3">
                      <ImagesPack symbol={article.symbol_art} />
                    </div>
                  )}
                </div>
              </div>
              {(article && <ArticleTabs item={article} files={articleFiles} isPopup={!!props?.isPopup} />)}
            </>
          )}
        </div>
      </main>
    </>
  );
};

export default ArticleShow;
