import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import parser from 'bbcode-to-react';

import STable from '../../components/grid/stable';
import SColumn from '../../components/grid/scolumn';

import { SpinnerAbsolute } from '../../components/spinner';

import {
  numberFormatCurrency, numberFormatQuantity, numberFormatVat, parseJSON, debounce, hashCode, isEmpty, sleep
} from '../../components/util';

import api from '../../components/service/api.ts';
import useCartAction from '../../components/hook/cartaction';
import LazyImage from '../../components/hook/lazyimage';

import CartSelect from '../../components/cart/cartselect';
import CartCopy from '../../components/cart/cartcopy';

import useFileCache from '../../components/hook/useFileCache';
import AlertDialog from '../../components/modal/AlertDialog';

import SelectNormal from '../../components/propselect/selectnormal';
import SelectCalendar from '../../components/propselect/selectcalendar';
import TextareaNote from '../cart/TextareaNote.tsx';
import InputSave from '../../components/InputSave';

import '../cart/select.css';

let timeoutSummary = null;

const ObjSettCart = {
  show_completion_date: false,
  show_delivery_method: false,
};

const Pattern = () => {
  const [cartState, cartAction] = useCartAction();
  const { t } = useTranslation("cart");

  const [dataMain, setDataMain] = useState(null);
  const [dataCart, setDataCart] = useState({});
  const [formCart, setFormCart] = useState({});
  const [loading, setLoading] = useState(0);
  const [resetMultiplier, setResetMultiplier] = useState(-1);
  const [settCart, setSettCart] = useState({...ObjSettCart}); // setttings of cart

  let isMounted = true;

  const refFormExt = useRef();

  const getCartList = async () => {
    const select = cartState.cart.selectPattern;
    const resp = await cartAction.getCart(select, 'W');
    return resp;
  };

  const getCartElementList = async (nagId) => {
    const resp = await cartAction.getCartElement(nagId);
    return resp;
  };

  const getCartForm = async () => {
    const resp = await api.getCartForm();
    return resp?.data;
  };

  const getData = () => {
    console.log("GETDATA", "pattern");
    setLoading(1);
    // setDataCart({});

    const dataList = [];

    new Promise((resove) => resove(1)).then(async () => {
      // CART FORM
      const _dataForm = await getCartForm();
      if (_dataForm?.status !== 0) {
        AlertDialog({
          title: 'Ostrzeżenie',
          message: `Nieudana próba pobrania form wzorca.\u00A0${_dataForm?.info || ""}`,
          color: 'yellow',
        });
        return false;
      }

      const newFormList = parseJSON(_dataForm.dane);
      setFormCart(Object.assign(formCart, newFormList));

      // CART LIST
      const _dataCart = await getCartList();
      if (_dataCart?.err !== null) {
        AlertDialog({
          title: 'Ostrzeżenie',
          message: `Nieudana próba pobrania nagłówków wzorca.\u00A0${_dataCart?.err || ""}`,
          color: 'yellow',
        });

        return false;
      }

      const cartList = _dataCart.data;
      // console.log('CART LIST', _dataCart, cartList, (cartList[0]?.id) > 0);

      if (cartList?.cart == undefined || !(cartList?.cart[0]?.id > 0)) {
        // AlertDialog({
        //   title: 'Ostrzeżenie',
        //   message: `Nieprawidłowy ID wzorca. Spróbuj ponownie.`,
        //   color: 'yellow',
        // });

        return false;
      }

      setDataCart(cartList.cart[0]);

      // CART SETT
      // show_completion_date
      const newSettCart = {...ObjSettCart};

      if (cartList?.show_completion_date == true || cartList?.show_completion_date == undefined) {
        newSettCart.show_completion_date = true;
      }

      // show_delivery_method
      if (cartList?.show_delivery_method == true || cartList?.show_delivery_method == undefined) {
        newSettCart.show_delivery_method = true;
      }

      setSettCart(newSettCart);

      // CART POZ
      const _dataCartElem = await getCartElementList(cartList.cart[0].id);
      // console.log('ELEM LIST', dataCartElem);

      if (_dataCartElem?.err !== null) {
        AlertDialog({
          title: 'Ostrzeżenie',
          message: `Nieudana próba pobrania pozycji wzorca.\u00A0${_dataCartElem?.err || ""}`,
          color: 'yellow',
        });

        return false;
      }

      const dataListTmp = _dataCartElem.data;
      if (!Array.isArray(dataListTmp) || dataListTmp.length == 0) {
        return false;
      }

      dataList.push(...dataListTmp);

      return true;
    }).then((flag) => {
      if (!flag) setDataCart({});
    }).finally(() => {
      setDataMain(dataList);
      setLoading(0);
    });
  };

  // useEffect(async () => {
  //   cartHook.getCartWithElements(0, 'W').catch(err => {
  //     console.log("cart W", err)
  //   });
  // }, []);

  useEffect(() => {
    isMounted = true;
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    console.log('FIRE pattern');
    if (loading > 0) return;

    // TODO: fix multiple fire summary list
    if (timeoutSummary != null) clearTimeout(timeoutSummary);
    timeoutSummary = setTimeout(() => {
      isMounted && getData();
    }, 300);
  }, [cartState.select, cartState.cart.event]);

  useEffect(() => {
    isMounted && setResetMultiplier(Math.random());
  }, [cartState.select]);

  const saveFormAll = async () => {
    if (refFormExt.current == undefined) return false;
    if (isMounted) setLoading(1);

    const listField = refFormExt.current.querySelectorAll("input[type='hidden'], input[name='nr_dok'], input[type='termin_r'], textarea");

    const paramList = Array
      .from(listField)
      .reduce((cum, el) => (
        { ...cum, [el.name]: el.value }
      ), {});

    const status = await cartAction.setCartExtension(cartState.cart.selectPattern, paramList, 'W');
    // Fix: debounce with callback prevent
    if (status) {
      const cartExt = cartAction.getCartExtension(cartState.cart.selectPattern);
      setDataCart(Object.assign(dataCart, { ...cartExt }));
    }
    if (isMounted) setLoading(0);
    return true;
  };

  const changeForm = () => debounce(_ => {
    // saveForm(e.target.name, e.target.value);
    const activeElem = document.activeElement;
    activeElem.blur();
    saveFormAll();
    activeElem.focus();
  }, 600)([]);

  const quantityTemplate = (item) => (
    <span data-prec={`${item.precision || 0}`}>
      {numberFormatQuantity(item.ilosc, item.precision || 0)}
    </span>
  );


  const priceNettoTemplate = (item) => (
    <span translate="no">{numberFormatCurrency(item.cena, item.currency)}</span>
  );

  const priceBruttoTemplate = (item) => (
    <span translate="no">{numberFormatCurrency(item.cena * numberFormatVat(item?.tax_rate), item.currency)}</span>
  );

  const priceNettoValueTemplate = (item) => (
    <span translate="no">{numberFormatCurrency(item.cena * item.ilosc, item.currency)}</span>
  );

  const priceBruttoValueTemplate = (item) => (
    <span translate="no">{numberFormatCurrency(item.cena * numberFormatVat(item?.tax_rate) * item.ilosc, item.currency)}</span>
  );

  const measureTemplate = (item) => (
    <span>
      {item.symbol_jmp}
    </span>
  );

  const nameTemplate = (item) => {
    const [loadArticleImage] = useFileCache();

    return (
      <>
        <div className="flex flex-col items-center md:flex-row md:items-start">
          <picture className="md:mr-2">
            <Link to={`/article/show/${encodeURIComponent(item.symbol_art)}`}>
              <LazyImage
                className="w-10 h-10 min-w-10"
                src=""
                symbol={item.symbol_art}
                width="50"
                height="50"
                alt=""
                handlerLoad={(symbol) => loadArticleImage(symbol, 50)}
              />
            </Link>
          </picture>
          <div className="flex-grow">
            <div className="text-sm font-medium leading-5 text-center text-gray-900 md:text-left">
              <Link to={`/article/show/${encodeURIComponent(item.symbol_art)}`}>
                <span className="">{parser.toReact(item.nazwa_art)}</span>
                {item.label && (
                  <span className="pl-2 text-sm leading-5 text-gray-400">({item.label})</span>
                )}
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  };

  const priceSummaryTemplate = (items, len) => {
    const priceSum = {
      netto: items.reduce((prev, curr) => prev + (curr.cena * curr.ilosc), 0),
      brutto: items.reduce((prev, curr) => prev + (curr.cena * curr.ilosc * numberFormatVat(curr?.tax_rate)), 0),
    };

    return (
      <>
        <td colSpan={len - 2} className="px-4 py-3 text-right whitespace-no-wrap md:ml-3">
          <span>Razem:</span>
        </td>
        <td className="px-4 py-3 text-right whitespace-no-wrap md:ml-3" translate="no">
          {numberFormatCurrency(priceSum.netto, items.length > 0 && items[0]?.currency)}
        </td>
        <td className="px-4 py-3 text-right whitespace-no-wrap md:ml-3" translate="no">
          {numberFormatCurrency(priceSum.brutto, items.length > 0 && items[0]?.currency)}
        </td>
      </>
    );
  };

  const lpTemplate = (_, index) => {
    return <span>{parseInt(index + 1, 10)}.</span>;
  };

  const vatTemplate = (item) => (
    <>
      {!isEmpty(item?.tax_lbl) && (
        <span>
          {item?.tax_lbl || "0%"}
        </span>
      )}
      {isEmpty(item?.tax_lbl) && (
        <span>
          {parseInt(item?.tax_rate == undefined ? 23 : item.tax_rate == 1 ? 0 : item.tax_rate, 10)}%
        </span>
      )}
    </>
  );

  const deleteArt = async (e, item) => {
    // console.log('PATTERN DEL POZ', item, dataMain);
    const target = e.currentTarget.closest('tr');
    target.style = 'transform:translateX(100%);transition:transform 0.5s 0s';

    setLoading(3);

    const success = await cartAction.setProduct({
      item,
      symbolJM: item.symbol_jmp,
      quantity: { input: 1, confirm: 0 },
      force: true,
      poz: item.poz_id,
      selectCart: cartState.cart.selectPattern,
    });

    if (!success) {
      setLoading(0);
      target.style = '';
      return;
    }

    if (dataMain !== null) {
      setDataMain(dataMain.filter(el => el.id != item.poz_id));
    }

    setLoading(0);
  };

  const deleteTemplate = (item) => (
    <>
      <button
        type="button"
        className="mt-1.5 text-red-600 bg-white border border-transparent rounded-full hover:border-red-600"
        onClick={(e) => deleteArt(e, item)}
        data-title={item.poz_id}
      >
        <svg className="w-5 h-5 border-2 border-transparent rounded-full" viewBox="0 0 24 24" fill="currentColor">
          <path d="M8 1.5V2.5H3C2.44772 2.5 2 2.94772 2 3.5V4.5C2 5.05228 2.44772 5.5 3 5.5H21C21.5523 5.5 22 5.05228 22 
            4.5V3.5C22 2.94772 21.5523 2.5 21 2.5H16V1.5C16 0.947715 15.5523 0.5
            15 0.5H9C8.44772 0.5 8 0.947715 8 1.5Z"
          />
          <path d="M3.9231 7.5H20.0767L19.1344 20.2216C19.0183 21.7882 17.7135 23 16.1426 23H7.85724C6.28636 23 4.98148 21.7882 4.86544 20.2216L3.9231 7.5Z" />
        </svg>
      </button>
    </>
  );

  return (
    <div className="relative">
      {loading > 0 && (
        <SpinnerAbsolute isFixed />
      )}
      <div className="flex flex-col items-center mb-4 md:flex-row">
        <span className="mb-2 mr-2 md:mb-0">
          <CartSelect name="Wzorzec" typ="W" />
        </span>
        {(dataMain != null && dataMain.length > 0) && <CartCopy resetMultiplier={resetMultiplier} />}
      </div>
      <div className="relative min-w-full pb-2 align-middle">
        <STable
          keyName="pattern_summary"
          data={dataMain || []}
          // summary={priceSummaryTemplate}
        >
          <SColumn
            className="px-4 py-3 md:ml-3 whitespace-no-wrap w-0.5 text-center md:text-left"
            template={deleteTemplate}
          />
          <SColumn
            name="Lp"
            className="px-4 py-3 md:ml-3 whitespace-no-wrap w-0.5 hidden md:table-cell"
            template={lpTemplate}
          />
          <SColumn
            name="Nazwa / Symbol"
            symbol="nazwa_art"
            className="items-center w-1/4 px-4 py-3 whitespace-no-wrap md:ml-3 name-hidden"
            template={nameTemplate}
          />
          <SColumn
            name="JM"
            symbol="symbol_jmp"
            className="w-3 px-4 py-3 text-center whitespace-no-wrap md:ml-3 md:text-left"
            template={measureTemplate}
          />
          <SColumn
            name="Ilość"
            symbol=""
            className="w-3 px-4 py-3 text-center whitespace-no-wrap md:ml-3 md:text-left"
            template={quantityTemplate}
          />
          <SColumn
            name=""
            symbol=""
            className="w-auto px-4 py-3 text-center whitespace-no-wrap md:ml-3 md:text-left"
          />
          {/* <SColumn
            name="cena netto"
            className="w-3 px-4 py-3 text-center whitespace-no-wrap md:ml-3 md:text-right"
            classHeader="text-right uppercase px-4 py-3"
            template={priceNettoTemplate}
          />
          <SColumn
            name="cena brutto"
            className="w-3 px-4 py-3 text-center whitespace-no-wrap md:ml-3 md:text-right"
            classHeader="text-right uppercase px-4 py-3"
            typeColumn="currency"
            defaultValue="0"
            template={priceBruttoTemplate}
          />
          <SColumn
            name="VAT"
            className="w-3 px-4 py-3 text-center whitespace-no-wrap md:ml-3 md:text-right"
            classHeader="text-right uppercase px-4 py-3"
            template={vatTemplate}
          />
          <SColumn
            name="Wartość netto"
            className="w-3 px-4 py-3 text-center whitespace-no-wrap md:ml-3 md:text-right"
            classHeader="text-right uppercase px-4 py-3"
            typeColumn="currency"
            defaultValue="0"
            template={priceNettoValueTemplate}
          />
          <SColumn
            name="Wartość brutto"
            className="w-6 px-4 py-3 text-center whitespace-no-wrap md:ml-3 md:text-right"
            classHeader="text-right uppercase px-4 py-3"
            typeColumn="currency"
            defaultValue="0"
            template={priceBruttoValueTemplate}
          /> */}
        </STable>
      </div>
      {(dataMain == null || dataMain.length === 0) && (
        <div className="relative px-4 py-3 mb-2 text-center text-yellow-700 transition bg-yellow-100 rounded" role="alert">
          <span className="block sm:inline">{t('Pusty wzorzec zakupu')}</span>
        </div>
      )}
      {dataMain != null && dataMain.length > 0 && (
        <div className="flex flex-col w-full mt-2" id="form_ext" ref={refFormExt}>
          <div className="flex flex-row">
            <div className="w-full">
              <label className="block text-sm leading-8">{t('Uwagi')}:</label>
              <TextareaNote 
                  className="w-full border border-gray-300 rounded shadow-sm md:w-auto md:max-w-300"
                  name="uwagi"
                  data-cy="order-comment-pattern"
                  maxLength={65000}
                  defaultValue={dataCart?.uwagi}
                  onChange={changeForm}
                />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Pattern;
